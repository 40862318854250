<template>
  <div class="invoicing-client-box">
    <Accordion
      :title="client['client_name']"
      :extra-content-html="`<span>${client['billing_sum']} €</span> <span>${client['days_sum']} d</span>`"
    >
      <h3>Projects</h3>
      <GroupableTable :data="client['projects']" :hide-grouping="true" />

      <h3>One time fees for {{ monthName }}</h3>
      <GroupableTable :data="client['one_time_fees']" :hide-grouping="true" />

      <h3>Recurring fees for {{ monthName }}</h3>
      <GroupableTable :data="client['recurring_fees']" :hide-grouping="true" />

      <h3>Special prices valid during {{ monthName }}</h3>
      <GroupableTable :data="client['special_prices']" :hide-grouping="true" />

      <h3>Time entries</h3>
      <GroupableTable :data="client['entries']" />

      <h3>Total billing</h3>
      <GroupableTable :data="client['fees']" :hide-grouping="true" />

      <div class="bottom">
        <div class="notes">
          <h3>Notes:</h3>
          <div>
            <p v-for="(note, index) in client['all_notes']" :key="index">{{ note }}</p>
          </div>
        </div>
        <div class="actions">
          <a
            class="button"
            target="_blank"
            :href="`/api/v1/invoicing/download-report/${client['id']}/${month.toFormat(
              'yyyy-M'
            )}?file_format=pdf`"
            >Download work report PDF</a
          >
        </div>
      </div>
    </Accordion>
  </div>
</template>

<script>
import { DateTime } from 'luxon'

import Accordion from './Accordion.vue'
import GroupableTable from './GroupableTable.vue'

export default {
  components: { GroupableTable, Accordion },
  props: {
    client: {
      type: Object,
      required: true,
    },
    month: {
      type: DateTime,
      required: true,
    },
  },

  data() {
    return {}
  },

  computed: {
    tableHeadings() {
      return Object.keys(this.client)
    },
    monthName() {
      return this.month.toFormat('MMMM, yyyy')
    },
  },
}
</script>

<style lang="scss">
@import '../../assets/scss/variables';

.invoicing-client-box {
  h3 {
    text-transform: uppercase;
    font-family: $font_title;
  }

  .extra-content {
    display: flex;
    justify-content: end;

    span {
      width: 100%;
      max-width: 5rem;

      &:first-child {
        border-right: 1px solid $light-grey;
        padding-right: 20px;
      }
    }
  }

  .groupable-table {
    padding-top: 5px;
  }

  .bottom {
    display: flex;

    > div {
      flex: 1;
    }

    .notes {
      background: $light-grey;
      padding: 15px;
      min-height: 30px;
    }

    .actions {
      display: flex;
      justify-content: end;
      align-items: center;
    }
  }
}
</style>
