<template>
  <div class="pie-chart">
    <h2 v-if="title">
      {{ title }}
    </h2>
    <div
      :style="{
        height: height || '40vh',
        width: '100%',
        position: 'relative',
      }"
      class="graph-area"
    >
      <canvas ref="chart" />
    </div>
    <div id="custom-legend" class="chart-legend"></div>
  </div>
</template>

<script>
import Chart from 'chart.js'

export default {
  props: {
    labels: {
      type: Array,
      default: () => [],
    },

    series: {
      type: Array,
      default: () => [],
    },
    categories: {
      type: Array,
      default: () => [],
    },
    raw: {
      type: Array,
      default: () => [],
    },
    height: {
      type: [String, Number],
      default: null,
    },

    title: {
      type: String,
      default: '',
    },

    seriesUnit: {
      type: String,
      default: '',
    },

    colors: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      chart: null,
    }
  },
  watch: {
    series(series) {
      this.update()
    },
  },
  mounted() {
    this.update()
    this.createCustomLegend()
  },
  methods: {
    update() {
      if (this.chart) {
        this.chart.destroy()
      }
      this.chart = new Chart(this.$refs.chart, {
        type: 'doughnut',
        data: {
          labels: this.labels,
          datasets: [
            {
              backgroundColor: this.colors,
              data: this.series,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false, // disable legend display
            position: 'bottom', // Position the legend at the bottom
          },
        },
      })
    },
    createCustomLegend() {
      const legend = document.getElementById('custom-legend')
      this.categories.forEach((category, index) => {
        const cat = document.createElement('div')
        cat.className = 'legend-column'
        const catTitle = document.createElement('h3')
        catTitle.textContent = category
        cat.appendChild(catTitle)

        this.raw.forEach((project, index) => {
          if (project.category !== category) {
            return
          }

          const item = document.createElement('div')
          item.className = 'legend-item'

          const itemColor = document.createElement('div')
          itemColor.className = 'legend-item-color'
          itemColor.style.backgroundColor = this.colors[index]

          const itemText = document.createElement('span')
          itemText.textContent = `${this.labels[index]}: ${this.series[index]}${this.seriesUnit}`

          item.appendChild(itemColor)
          item.appendChild(itemText)

          cat.appendChild(item)
        })
        legend.appendChild(cat)
      })
    },
  },
}
</script>

<style lang="scss">
.pie-chart {
  display: block;
}

.chart {
  font-family: Montserrat, sans-serif;
  height: 100%;
}

h2 {
  margin-bottom: 50px;
  margin-top: 0;
  text-align: center;
  font-size: 1.8em;
}

.chart-legend {
  margin-left: 50px;
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.legend-column {
  display: flex;
  flex-direction: column;
  width: 33.33%;

  > h3 {
    margin-left: 30px;
    text-transform: capitalize;
  }
}

.legend-item {
  display: flex;
  align-items: center;
  width: 100%;
  text-transform: capitalize;
  margin-bottom: 10px;
}

.legend-item-color {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 10px;
}
</style>
